const isBrowser = typeof window !== `undefined`

const getLocation = () =>
  window.localStorage.vusiemlocation
    ? JSON.parse(window.localStorage.vusiemlocation)
    : {}

  {/*  function getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }

  */}
  
const setLocation = location => (window.localStorage.vusiemlocation = JSON.stringify(location))

export const handleLocation = ({ locationCode }) => {
  if (!isBrowser) return false
  
  if (locationCode && locationCode !== `DE`) {
      
    return setLocation({
      locationCode: locationCode,
    })
  }

  return false
}

export const isValidLocation = () => {
  if (!isBrowser) return false

  const location = getLocation()

  return !!location?.locationCode
}

export const getCurrentLocation = () => isBrowser && getLocation()

export const logout = callback => {
  if (!isBrowser) return

  console.log(`Ensuring the \`vusiemlocation\` property exists.`)
  setLocation({})
  callback()
}